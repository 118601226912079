export default {
    version: '2.6',
    base_admin_url: 'https://spack-admin.codedott.com',
    database_errors: [],
    item_errors: [],
    verification_errors: [],
    installation_errors: [],
    processing: false,
    success: false,
    token: '',
    installation_failed: false,
    installation_started: false,
    installation_step: 0,
    installation_steps: [
        'checkInstallerVersion',
        'checkDatabase',
        'verify',
        'download',
        'extract',
        'config',
        'recipe',
    ],
    installation_process: [
        'Check Installer Version',
        'Check Database',
        'Purchase Verification',
        'Download Files',
        'Extract Files',
        'Update Configuration',
        'Finalizing Installation',
    ],
    form: {
        host: '127.0.0.1',
        port: '3306',
        database: '',
        password: '',
        username: '',
        purchase_code: '',
        item: 'spack-32',
    },

    install() {
        if (this.processing) return

        if (!this.validate()) return

        this.processing = true
        this.installation_started = true
        this.installation_failed = false
        this.installation_errors = []

        this[this.installation_steps[this.installation_step]]()
    },

    validate() {
        this.item_errors = []
        this.database_errors = []
        this.verification_errors = []

        if (!this.form.host) {
            this.database_errors.push('database host required!')
        }
        if (!this.form.port) {
            this.database_errors.push('database port is required!')
        }
        if (!this.form.database) {
            this.database_errors.push('database name is required!')
        }
        if (!this.form.username) {
            this.database_errors.push('database username is required!')
        }

        if (!this.form.purchase_code) {
            this.verification_errors.push('purchase code is required!')
        }

        if (!this.form.item) {
            this.item_errors.push('Please choose an item!')
        }

        if (!this.form.host || !this.form.port || !this.form.database || !this.form.username || !this.form.purchase_code || !this.form.item) {
            return false
        }

        return true
    },

    checkInstallerVersion() {
        axios.post(this.base_admin_url + '/api/check-installer', {
            version: this.version
        })
            .then(response => {
                console.log('response check installer!')
                console.log(response.data)

                if (!response.data.success) {
                    this.processing = false
                    this.installation_errors.push('This installer is outdated. Download the fresh one from Codecanyon.')
                    this.installation_failed = true
                    return
                }

                if (response.data.success) {
                    this.installation_step++
                    this.checkDatabase()
                }
            })
            .catch(error => {
                console.log('error in check installer!')
                console.log(error.response.data)
                this.processing = false
            })
    },

    checkDatabase() {
        axios.post('/install/src/check-database.php', this.form)
            .then(response => {
                console.log('response checkDatabase!')
                console.log(response.data)

                if (!response.data.success && response.data.error) {
                    this.processing = false
                    this.installation_failed = true
                    this.database_errors.push(response.data.error)
                    this.installation_errors.push(response.data.error)
                    return
                }

                if (response.data.success) {
                    this.installation_step++
                    this.verify()
                }
            })
            .catch(error => {
                console.log('error in checkDatabase!')
                console.log(error.response.data)
            })
    },

    verify() {
        this.form['domain'] = window.location.host
        this.form['url'] = window.location.href

        axios.post(this.base_admin_url + '/api/verify-installation', this.form)
            .then(response => {
                console.log('response verify!')
                console.log(response.data)

                if (!response.data.success) {
                    this.processing = false
                    this.verification_errors.push(response.data.error)
                    this.installation_errors.push(response.data.error)
                    this.installation_failed = true
                    return
                }

                if (response.data.success) {
                    this.installation_step++
                    this.token = response.data.token
                    this.download()
                }
            })
            .catch(error => {
                console.log('error in verify!')
                console.log(error.response.data)
                this.processing = false
                this.installation_failed = true
            })
    },

    download() {
        axios.post('/install/src/download.php', {
            token: this.token,
            item: this.form.item,
        })
            .then(response => {
                console.log('response download!')
                console.log(response.data)

                if (response.data.success) {
                    this.installation_step++
                    this.extract()
                } else {
                    this.processing = false
                    this.installation_failed = true
                    this.installation_errors.push(response.data.error)
                    this.recordError(response.data)
                }
            })
            .catch(error => {
                console.log('error')
                console.log( error.response.data )
                this.recordError(error.response.data)
            })
    },

    extract() {
        axios.post('/install/src/extract.php')
            .then(response => {
                console.log('response extract!')
                console.log(response.data)

                if (response.data.success) {
                    this.installation_step++
                    this.config()
                } else {
                    this.processing = false
                    this.installation_failed = true
                    this.installation_errors.push(response.data.error)
                    this.recordError(response.data)
                }
            })
            .catch(error => {
                console.log('error')
                console.log( error.response.data )
                this.recordError(error.response.data)
            })
    },

    config() {
        axios.post('/install/src/config.php', this.form)
            .then(response => {
                console.log('response config!')
                console.log(response.data)

                if (response.data.success) {
                    this.installation_step++
                    this.recipe()
                } else {
                    this.processing = false
                    this.installation_failed = true
                    this.installation_errors.push(response.data.error)
                    this.recordError(response.data)
                }
            })
            .catch(error => {
                console.log('error')
                console.log(error.response.data)
                this.recordError(error.response.data)
            })
    },

    recipe() {
        axios.post('/installation-recipe', { token: this.token })
            .then(response => {
                console.log('response recipe!')
                console.log(response.data)

                if (response.data.success) {
                    this.success = true
                } else {
                    this.processing = false
                    this.installation_failed = true
                    this.installation_errors.push(response.data.error)
                    this.recordError(response.data)
                }
            })
            .catch(error => {
                console.log('error in recipe!')
                console.log(error.response.data)
                this.recordError(error.response.data)
            })
    },

    copyInstallerIndex() {
        axios.post('/install/src/copy-installer-index.php')
            .then(response => {
                console.log('response copyInstallerIndex!')
                console.log(response.data)
                this.copy_installer_index = true
            })
            .catch(error => {
                console.log('error copy installer index')
                console.log(error.response.data)
                this.recordError(error.response.data)
            })
    },

    copyLaravelIndex() {
        axios.post('/install/src/copy-laravel-index.php')
            .then(response => {
                console.log('response copyLaravelIndex!')
                console.log(response.data)
            })
            .catch(error => {
                console.log('error copy laravel index')
                console.log(error.response.data)
                this.recordError(error.response.data)
            })
    },

    recordError(payload) {
        axios.post(this.base_admin_url + '/api/installation/status', {
            payload,
            token: this.token
        })
            .then(response => {
                console.log('response recordError!')
                console.log(response.data)
                this.processing = false
            })
            .catch(error => {
                console.log('error in recordError!')
                console.log(error.response.data)
                this.processing = false
            })
    },
}
